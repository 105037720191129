<template>
	<v-sheet class="group" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="3" class="py-0 my-auto">
				<v-select
					v-model="status"
					class="listing-select hide-accordion"
					hide-details
					:disabled="true"
					:items="listingStatusArr"
					label="Status"
					solo
					v-on:change="filterRows"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-chip :color="item.color" :text-color="item.textcolor">
								{{ item.status_count }}
							</v-chip>
						</v-list-item-action>
					</template>
				</v-select>
			</v-col>
			<v-col md="9" class="py-1 my-auto text-right listing-right">
				<v-layout class="justify-end">
					<div class="mx-2" style="max-width: 400px">
						<date-range-picker
							v-on:click:clear="
								s_search['date-range'] = [];
								searchOrders();
							"
							hide-details
							hide-top-margin
							clearable
							v-model="s_search['date-range']"
						></date-range-picker>
					</div>
					<div class="mx-2" style="max-width: 220px">
						<select-input
							v-on:click:clear="
								s_search['incharge-officer'] = [];
								searchOrders();
							"
							clearable
							:items="incharge_items"
							hide-details
							custom-class="pt-0"
							placeholder="Incharge Officer"
							multiple
							v-model="s_search['incharge-officer']"
						></select-input>
					</div>
					<div class="mx-2" style="max-width: 220px">
						<select-input
							v-on:click:clear="
								s_search['r-type'] = [];
								searchOrders();
							"
							clearable
							:items="item_type_items"
							hide-details
							custom-class="pt-0"
							placeholder="Type"
							multiple
							v-model="s_search['r-type']"
						></select-input>
					</div>
					<div class="mx-2" style="max-width: 220px">
						<select-input
							v-on:click:clear="
								s_search['t-type'] = [];
								searchOrders();
							"
							clearable
							:items="transaction_type_items"
							hide-details
							custom-class="pt-0"
							placeholder="Transaction Type"
							multiple
							v-model="s_search['t-type']"
						></select-input>
					</div>
					<div class="d-flex">
						<v-btn
							color="blue darken-4 text-white"
							class="mx-2"
							depressed
							tile
							:disabled="pageLoading"
							v-on:click="searchOrders()"
							><v-icon center> mdi-magnify </v-icon></v-btn
						>
						<v-btn
							depressed
							:disabled="pageLoading"
							color="blue darken-4"
							class="text-white"
							tile
							v-on:click="exportDialog = true"
						>
							<v-icon left>mdi-file-excel</v-icon>
							Export
						</v-btn>
						<v-menu
							bottom
							left
							origin="center center"
							transition="slide-y-transition"
							rounded="0"
							offset-y
							max-height="400px"
							:close-on-content-click="false"
							content-class="white-background"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="blue darken-4"
									class="text-white"
									tile
									:disabled="pageLoading"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-table-edit</v-icon>
								</v-btn>
							</template>
							<Draggable
								tag="ul"
								v-model="draggableThead"
								class="draggable-group"
								handle=".draggable-drag-icon"
								v-on:change="updateTable('group')"
							>
								<template v-for="cols in draggableThead">
									<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
										<v-checkbox
											dense
											v-model="draggableTheadShow"
											v-bind:value="cols.key"
											:label="cols.name"
											:disabled="cols.fixed || pageLoading"
											color="blue"
											hide-details
											class="mt-0 mb-0"
											v-on:change="updateTableVisiblity('group')"
										></v-checkbox>
										<v-icon
											v-if="!pageLoading && !cols.fixed"
											class="draggable-action draggable-drag-icon"
											right
											color="blue"
											>mdi-drag</v-icon
										>
										<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
									</li>
								</template>
							</Draggable>
						</v-menu>
					</div>
				</v-layout>
			</v-col>
		</v-row>
		<SearchCriteria
			v-if="searchEnabled()"
			:search-fields="listingFilter"
			search-string="Record #, Asset #, Asset Stock #, Perishable #, Asset Name, Asset Stock Name, Perishable Name, Incharge Officer"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<Table :page-loading="pageLoading" type="transaction" v-on:reload:content="searchOrders"></Table>
		<template v-if="exportDialog">
			<ExportDialog
				endpoint="transaction/export?export=1"
				title="Transaction"
				:export-dialog.sync="exportDialog"
				v-on:close="exportDialog = false"
			></ExportDialog>
		</template>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import SelectInput from "@/view/components/SelectInput";
import DateRangePicker from "@/view/components/DateRangePicker";
import ObjectPath from "object-path";
import { mapGetters } from "vuex";
import { isString, toSafeInteger, map } from "lodash";

export default {
	name: "transaction-listing",
	title: "Listing Transaction",
	mixins: [ListingMixin],
	data() {
		return {
			exportDialog: false,
			s_search: {
				"date-range": [],
				"incharge-officer": [],
				"r-type": [],
				"t-type": [],
			},
			incharge_items: [],
			item_type_items: [
				{ text: "Asset", value: "asset" },
				{ text: "Asset Stock", value: "asset-stock" },
				{ text: "Perishable", value: "perishable" },
			],
			transaction_type_items: [
				{ text: "Issued", value: "issued" },
				{ text: "Received", value: "received" },
				{ text: "Overdue", value: "overdue" },
				{ text: "Retired", value: "retired" },
			],
			listingStatusArr: [
				{
					id: 10,
					type: "issue",
					text: "All Transactions",
					value: "all-transaction",
					description: "List of All Transactions",
					color: "blue",
					textcolor: "white",
					dbvalue: 99,
					status_count: 0,
					order: 0,
				},
			],
			status: "all-transaction",
			pageTitle: "Transactions",
			pageBreadcrumbs: [{ text: "Transactions", disabled: true }],
			endpoint: "order-transactions",
			defaultFilter: {},
		};
	},
	components: {
		SelectInput,
		DateRangePicker,
	},
	methods: {
		searchOrders() {
			this.$router
				.replace({
					name: this.$route.name,
					query: {
						...this.$route.query,
						...this.s_search,
					},
				})
				.then(() => {
					this.getListing();
				})
				.catch(() => {});
		},
	},
	mounted() {
		this.incharge_items = this.localDB("members", []);

		const date_range = ObjectPath.get(this.$route, "query.date-range", []);
		if (isString(date_range)) {
			this.s_search["date-range"] = [date_range];
		} else {
			this.s_search["date-range"] = date_range;
		}

		const incharge_officer = ObjectPath.get(this.$route, "query.incharge-officer", []);
		if (isString(incharge_officer)) {
			this.s_search["incharge-officer"] = [toSafeInteger(incharge_officer)];
		} else {
			this.s_search["incharge-officer"] = map(incharge_officer, (row) => {
				return toSafeInteger(row);
			});
		}

		const r_type = ObjectPath.get(this.$route, "query.r-type", []);
		if (isString(r_type)) {
			this.s_search["r-type"] = [r_type];
		} else {
			this.s_search["r-type"] = r_type;
		}

		const t_type = ObjectPath.get(this.$route, "query.t-type", []);
		if (isString(t_type)) {
			this.s_search["t-type"] = [t_type];
		} else {
			this.s_search["t-type"] = t_type;
		}
	},
	computed: {
		...mapGetters(["localDB"]),
	},
};
</script>
